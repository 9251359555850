import React from "react";
import pgSuccess from "../assets/Images/pg-success.svg";
import {NavLink} from "react-router-dom";

const PgSuccess = () => {
    return (
        <>
            <section>
                <div className="container">
                    <div className="hero-sec hero-h">
                        <div className="authbody">
                            <img src={pgSuccess} alt="img" className="pg-logo-w" />
                            <div className="payment-dec">
                                <p>Thank You <br/> Your deposit is successful <br/> For Updated wallet balance check in app</p>
                            </div>
                            <NavLink to={"/home"} className="primarybtn d-inline-b">
                                Go To Home
                            </NavLink>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default PgSuccess;
