import "./App.css";
import ScrolltoTop from "./ScrolltoTop";
import { BrowserRouter as Router, Routes, Route, useLocation } from "react-router-dom";
import Home from "./features/home/Home";
import Footer from "./features/footer/Footer";
import PrivacyPolicy from "./features/privacypolicy/PrivacyPolicy";
import TermsCondition from "./features/termscondition/TermsCondition";
import Header from "./features/header/Header";
import AboutUs from "./features/about/AboutUs";
import ContactUs from "./features/contact/ContactUs";
import RefundReturn from "./features/refundreturnpolicy/RefundReturn";
import GameRule from "./features/gamerule/GameRule";

import PgSuccess from "./pgsuccess/PgSuccess";
import PgPending from "./pgpending/pgPending";
import PgFailed from "./pgfailed/pgFailed";
import PaymentPage from "./payment/PaymentPage";


function AppWrapper() {
  const location = useLocation();
  const hideHeaderFooter = location.pathname === "/payment/checkout";

  return (
      <>
        {!hideHeaderFooter && <Header />}
        <ScrolltoTop />
        <Routes>
          <Route index element={<Home />} />
          <Route path="/home" element={<Home />}></Route>
          <Route path="/privacy-policy" element={<PrivacyPolicy />}></Route>
          <Route path="/terms-condition" element={<TermsCondition />}></Route>
          <Route path="/refund-return" element={<RefundReturn />}></Route>
          <Route path="/about-us" element={<AboutUs />}></Route>
          <Route path="/contact-us" element={<ContactUs />}></Route>
          <Route path="/game-rules" element={<GameRule />}></Route>
          <Route path="/txn/success" element={<PgSuccess />} ></Route>
          <Route path="/txn/pending" element={<PgPending />} ></Route>
          <Route path="/txn/failed" element={<PgFailed />} ></Route>
          <Route path="/payment/checkout" element={<PaymentPage />} ></Route>
        </Routes>
        {!hideHeaderFooter && <Footer />}
      </>
  );
}

function App() {
  return (
      <Router>
        <AppWrapper />
      </Router>
  );
}



export default App;
