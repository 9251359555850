import React, { useEffect, useRef } from "react";

const PaymentPage = () => {
    const checkoutPgRef = useRef(null); // Ref for the checkout div

    useEffect(() => {
        const queryParams = new URLSearchParams(window.location.search);
        const sessionId = queryParams.get("sessionId");

        if (sessionId) {
            if (!window.Cashfree) {
                console.error("Cashfree SDK is not loaded.");
            } else {
                const cashfree = window.Cashfree({
                    mode: "sandbox", // Use "production"  'sandbox' for live payments
                });

                const checkoutOptions = {
                    paymentSessionId: sessionId,
                    redirectTarget: checkoutPgRef.current, // Use the ref for the target div
                };

                // Apply styles to the checkout container
                if (checkoutPgRef.current) {
                    checkoutPgRef.current.style.display = "flex";
                    checkoutPgRef.current.style.justifyContent = "center";
                    checkoutPgRef.current.style.alignItems = "center";
                }

                try {
                    cashfree.checkout(checkoutOptions);
                } catch (error) {
                    console.error("Error during Cashfree checkout:", error);
                }
            }
        } else {
            console.error("Session ID is missing from the URL.");
        }
    }, []); // Run only once when the component mounts

    return (
        <div>
            <div ref={checkoutPgRef}></div>
            <div id="root" style={{ display: "block" }}>
            </div>
        </div>
    );
};

export default PaymentPage;
